import React, { Component } from "react";

import {
  Box,
  Typography,
  Button,
  Modal,
} from "@material-ui/core";

import {
  createTheme,
  Theme,
  ThemeProvider,
  WithStyles,
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { configJSON } from "../../blocks/landingpage/src/LandingPageController.web";
import { Link } from "react-router-dom";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface MyProps extends WithStyles<typeof styles> {
  handleModal?: () => void,
  isModalOpen: boolean
};

interface MyState {
  isModalOpen: boolean;
};

class LandingPageModal extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isModalOpen: this.props.isModalOpen
    }
  }

  render() {
    const { handleModal, isModalOpen, classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Modal
          open={isModalOpen}
          onClose={handleModal}
          data-test-id="modal"
        >
          <Box className={classes.modalContainer}>
            <Box className={classes.modalCancelBtnContainer}>
              <Button
                variant="outlined"
                data-test-id="modalCloseIcon"
                onClick={handleModal}
                className={classes.modalCanelBtn}
              >
                <CloseIcon className={classes.modalCloseIcon} />
              </Button>
            </Box>

            <Box className={classes.modalTextFirstRow}>
              <Typography variant="h6" className={classes.modalTextCreateAccount}>
                {configJSON.loginText}
              </Typography>
              <Typography className={classes.modalOrText}>
                {configJSON.orText}
              </Typography>
              <Typography variant="h6" className={classes.modalTextCreateAccount}>
                {configJSON.createAccountText}
              </Typography>
            </Box>

            <Typography className={classes.modalRowTwoText}>
              {configJSON.modalRowTwoText}
            </Typography>

            <div className={classes.modalBtnContainer}>
              <Link to={"EmailAccountLoginBlock"}>
                <Button
                  variant="contained"
                  data-test-id="modalLoginBtn"
                  className={classes.modalColorButton}
                >
                  Login
                </Button>
              </Link>
              <Link to={"EmailAccountRegistration"}>
                <Button
                  variant="contained"
                  data-test-id="modalCreateAccountBtn"
                  className={classes.modalColorButton}
                >
                  Create Account
                </Button>
              </Link>
            </div>

          </Box>
        </Modal>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "470px",
    width: "100%",
    height: "338px",
    backgroundColor: "#2A4E51",
    boxShadow: `5px 20px 40px 0px rgba(0, 0, 0, 0.20)`,
    padding: "9px 18px 12px",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "@media (max-width: 767px)": {
      maxWidth: "80%",
      height: "auto",
    }
  },
  modalColorButton: {
    color: "#000",
    backgroundColor: "#FFFFFF",
    fontFamily: "Mukta Mahee, sans-serif",
    fontSize: '16px',
    fontWeight: 600,
    textTransform: "capitalize",
    borderRadius: "8px",
    height: 56,
    width: "100%",
    padding: "10px 15px",
    boxSizing: "border-box",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    "@media (max-width: 640px)": {
      height: 48,
    }
  },
  modalCanelBtn: {
    backgroundColor: "#2A4E51",
    boxShadow: "none",
    border: "none",
    color: "#000000",
    marginTop: "-3px",
    width: "auto",
    padding: 5,
    minWidth: "auto",
    borderRadius: 50,
    '&:hover': {
      backgroundColor: "#2A4E51",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "3px",
      marginRight: "0px"
    },
  },
  modalCancelBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "12px"
  },
  modalCloseIcon: {
    fontSize: "31px",
    color: "#FFFFFF"
  },
  modalBtnContainer: {
    margin: "41px 30px 25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 30,
    rowGap: 10,
    "& a": {
      maxWidth: "176px",
      width: "100%",
    },
    "@media (max-width: 640px)": {
      margin: "25px",
      flexDirection: "column",
      "& a": {
        maxWidth: "100%",
      }
    },
  },
  modalTextFirstRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "5px",
    "& *": {
      color: "#FFFFFF",
      fontStyle: "normal",
      fontSize: "26px",
      fontFamily: "Mukta Mahee, sans-serif",
      "@media (max-width: 640px)": {
        fontSize: 23,
      }
    }
  },
  modalTextCreateAccount: {
    margin: "2px",
    fontWeight: 600,
  },
  modalOrText: {
    margin: "0 4px",
  },
  modalRowTwoText: {
    width: "100%",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "26px",
    marginTop: "-12px",
    marginBottom: "5px",
    textTransform: "lowercase",
    fontFamily: "Mukta Mahee, sans-serif",
    "@media (max-width: 640px)": {
      fontSize: 23,
    },
  },
});

export default withStyles(styles)(LandingPageModal) as any;