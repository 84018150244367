import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import mapboxgl from 'mapbox-gl'
import { ChangeEvent, createRef } from "react";
import { handleResponseMessage } from "../../utilities/src/handle-response-message";
import { getStorageData } from "../../../framework/src/Utilities";
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { getTileAndOffset, fetchTile, getPixelColor, mapRGBAToRating } from "../../../components/src/GetRiskRating.web";
interface IMap {
  getSource: (id: string) => {
    _data: {
      features: {
        type: string;
        geometry: {
          type: string;
          coordinates: number[];
        };
      }[]
    },
    setData: (_data: ReturnType<IMap['getSource']>['_data']) => {}
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  lng: number,
  lat: number,
  zoom: number,
  available_years: number[],
  available_hazards: string[],
  selectedYear: string,
  selectedHazard: string,
  isModalOpen: boolean,
  error: string,
  url: string,
  isLoggedIn: boolean,
  isShowErrorModal: boolean,
  isAddingAsset: boolean,
  token: string,
  placeName: string;
  stateName: string;
  currentRiskUrl: string;
  futureRiskUrl: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRastersCallId: string = "";
  getFilterRiskCallId: string = "";
  postAssetCallId: string = "";
  fetchRasterLinkCallId: string = "";
  fetchCurrentRiskRatingCallId: string = "";
  fetchFutureRiskRatingCallId: string = "";
  getReverseCodeId: string = ""
  mapContainer: React.RefObject<HTMLDivElement>;
  map = createRef<undefined | IMap | mapboxgl.Map>();
  marker = createRef<mapboxgl.Marker | null>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      currentRiskUrl: "",
      futureRiskUrl: "",
      lng: 124.5085,
      lat: 8.7832,
      zoom: 5,
      available_years: [],
      available_hazards: [],
      selectedYear: "",
      selectedHazard: "",
      isModalOpen: false,
      error: "",
      url: "",
      isLoggedIn: false,
      isShowErrorModal: false,
      isAddingAsset: false,
      token: "",
      placeName: "",
      stateName: ""
    };
    this.mapContainer = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAllRastersCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorJson,
          onSuccess: () => {
            responseJson && this.setState({
              available_years: responseJson.years_list, available_hazards: responseJson.hazard_names_list,
              selectedYear: responseJson.default_year, selectedHazard: responseJson.default_hazard
            })
          },
          onFail: () => {
            this.setState({ error: responseJson?.error }, this.handleShowErrorModal)
          }
        });
      }
      else if (apiRequestCallId === this.fetchRasterLinkCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorJson,
          onSuccess: () => {
            this.setState({ currentRiskUrl: responseJson.current_risk, futureRiskUrl: responseJson.future_risk })
          },
          onFail: () => {
          }
        });
      }
      else if (apiRequestCallId === this.getFilterRiskCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorJson,
          onSuccess: () => {
            this.setState({ url: responseJson?.url }, this.onLoadMap)
          },
          onFail: () => {
            this.setState({ error: responseJson?.error, url: "" }, () => {
              this.handleShowErrorModal()
              this.onLoadMap()
            })
          }
        });
      } else if (apiRequestCallId === this.postAssetCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorJson,
          onSuccess: () => {
            responseJson.data && this.handleNavigation()
          },
          onFail: () => {
            this.handleShowErrorModal()
          }
        });
      }
      else if (apiRequestCallId === this.getReverseCodeId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorJson,
          onSuccess: () => {
            this.handleGetReverseCodeApiSuccess(responseJson)
          },
          onFail: () => {
            this.handleShowErrorModal()
          }
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.handleCheckLoggedIn()
    this.onLoadMap()
    this.handleGetList()
    this.onFetchRasterLink();
  }

  async componentDidUpdate(_: Readonly<Props>,
    prevState: Readonly<S>): Promise<void> {
    if (prevState.selectedYear !== this.state.selectedYear || prevState.selectedHazard !== this.state.selectedHazard) {
      this.handleGetFilterRisk()
    }
  }

  handleGetReverseCodeApiSuccess = (responseJson: any) => {
    const stateName = responseJson?.features[0]?.context?.find((item: {
      "id": string,
      "mapbox_id": string,
      "wikidata": string,
      "short_code": string,
      "text": string
    }) => {
      if (item.id.includes('district') || item.id.includes('region') || item.id.includes('place')) {
        return true;
      }

      return false;
    });
    this.setState({
      placeName: responseJson?.features[0]?.place_name ?? "Unknown",
      stateName: stateName ? stateName?.text : "Unknown"
    });
  }

  handleNavigation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyAssets");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleCheckLoggedIn = async () => {
    const authToken = await getStorageData('authToken');
    this.setState({ isLoggedIn: Boolean(authToken), token: authToken });
  }

  handleChange = (event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    const { name, value } = event.target;
    name && this.setState({ ...this.state, [name]: value })
  }

  handleGetList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRastersCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRastersEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleGetFilterRisk = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterRiskCallId = requestMessage.messageId;
    const data = {
      "year": this.state.selectedYear,
      "hazard": this.state.selectedHazard
    }
    const urlParams = new URLSearchParams(data).toString()
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilterRiskEndPoint}?${urlParams}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  onFetchRasterLink = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRasterLinkCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchRasterLink
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fetchRasterLinkMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  onAddAsset = () => {

    if (!this.state.token) {
      this.setState({ isModalOpen: true });
      return;
    }

    const { lat, lng, placeName, stateName } = this.state
    const countryName = placeName !== "" ? placeName.substring(placeName.length, placeName.lastIndexOf(",") + 1) : "Unknown";

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token,
    };
    const zoom = 10;

    const coords = getTileAndOffset(lat, lng, zoom);

    fetchTile(this.state.currentRiskUrl, coords.tileX, coords.tileY, zoom)
      .then((img) => {
        const color = getPixelColor(img, coords.offsetX, coords.offsetY);
        const currentRating = mapRGBAToRating(color);

        fetchTile(this.state.futureRiskUrl, coords.tileX, coords.tileY, zoom)
          .then((img) => {
            const color = getPixelColor(img, coords.offsetX, coords.offsetY);
            const futureRating = mapRGBAToRating(color);

            const body = {
              "asset": {
                "asset_name": stateName,
                "country": countryName,
                "city": stateName,
                "current_risk": currentRating,
                "future_risk": futureRating,
                "zip_code": 0,
                "latitude": lat,
                "longitude": lng,
                "street_address": "Unknown",
                "address": "Unknown",
                "general_construction_type": "Unknown",
                "general_building_purpose": "Unknown",
                "asset_value": 0,
                "keyword": "Unknown",
                "detailed_construction_type": "Unknown",
                "detailed_building_purpose": "Unknown",
                "build_material": "Unknown",
                "flood_wall": 0,
                "presence_of_basement": "Unknown",
                "type_of_foundation": "Unknown",
                "roof_material": "Unknown",
                "roof_geometry": "Unknown",
                "window_to_wall": "Unknown",
                "presence_of_window": "Unknown",
                "ground_raised": 0
              }
            }


            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postAssetCallId = requestMessage.messageId;

            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.addAssetEndPoint
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              header
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(body)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.exampleAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return requestMessage.messageId;
          })
      })


  }

  handleShowErrorModal = () => {
    this.setState({ isShowErrorModal: true })
    setTimeout(() => { this.setState({ isShowErrorModal: false }) }, 3000)
  }

  handleModal = () => {
    !this.state.isLoggedIn && this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  handleMapMarkerDrag = () => {
    const lnglat = this.marker.current?.getLngLat()
    return lnglat && this.setState({
      isAddingAsset: true,
      lat: lnglat.lat,
      lng: lnglat.lng,
    }, () => { this.reverseGeoCode(this.state.lat, this.state.lng) })
  }

  onLoadMap = () => {
    const { lng, lat, zoom } = this.state;
    mapboxgl.accessToken = configJSON.access_token;
    const example_tiles = this.state.url
    const map = new mapboxgl.Map({
      container: this.mapContainer.current as HTMLDivElement,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });
    this.map = {
      current: map
    }


    map.on('load', () => {
      map.addLayer({
        id: 'myRasterLayer',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [example_tiles + `/{z}/{x}/{y}.png?access_token=` + mapboxgl.accessToken],
          tileSize: 256
        },
        paint: {
          'raster-opacity': 1,
          'raster-resampling': 'nearest'
        }
      });

      map.addSource('myAssets', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      map.addLayer({
        id: 'selectedLocationMarker',
        type: 'circle',
        source: 'myAssets',
        paint: {
          'circle-radius': 10,
          'circle-color': '#007cbf'
        }
      });

      const naviagtionCntrl = new mapboxgl.NavigationControl(
        { showZoom: true, visualizePitch: false, showCompass: false }
      )
      map.addControl(naviagtionCntrl, "bottom-right")
      const locateMe = new mapboxgl.GeolocateControl(
        {
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserLocation: true,
          showUserHeading: true,
          showAccuracyCircle: true,
        })

      map.addControl(locateMe, "bottom-right")
      map.on("click", (event) => {
        this.marker.current?.remove()
        const mapMarker = new mapboxgl.Marker({ draggable: true, color: "#28494B" }).setLngLat(event.lngLat).addTo(map)
        mapMarker.on("dragend", this.handleMapMarkerDrag)
        this.marker = {
          current: mapMarker
        }
        this.setState({
          isAddingAsset: true,
          lat: event.lngLat.lat,
          lng: event.lngLat.lng,
        }, () => { this.reverseGeoCode(this.state.lat, this.state.lng) })
      })
    })


    const searchBox = new MapboxGeocoder({
      marker: false,
      autocomplete: true,
      accessToken: mapboxgl.accessToken,
      localGeocoder: this.coordinatesGeocoder as (query: string) => Result[],
      zoom: 8,
      placeholder: 'Search',
      //@ts-ignore
      mapboxgl: mapboxgl,
      reverseGeocode: true,
    })

    searchBox.on("result", (event) => {
      console.log("@@__coordinates__", event.result.geometry.coordinates);
      this.marker.current?.remove();
      const marker = new mapboxgl.Marker({ draggable: true, color: "#28494B" }).setLngLat(event.result.geometry.coordinates).addTo(map)
      marker.on("dragend", this.handleMapMarkerDrag)
      this.marker = {
        current: marker
      }
      this.setState({
        isAddingAsset: true,
        lat: event.result.geometry.coordinates[1],
        lng: event.result.geometry.coordinates[0],
        placeName: event?.result?.place_name,
      }, () => { this.reverseGeoCode(this.state.lat, this.state.lng) })
    })

    const GeoCoder = document.getElementById('geocoder')?.children
    if (GeoCoder?.length && GeoCoder?.length > 0) {
      document.getElementById('geocoder')?.removeChild(GeoCoder[0])
      //@ts-ignore
      document.getElementById('geocoder')?.appendChild(searchBox.onAdd(map));
    }
    else {
      //@ts-ignore
      document.getElementById('geocoder')?.appendChild(searchBox.onAdd(map));
    }

  }

  coordinatesGeocoder = function (query: string) {
    let matches: string[] | null = []
    if (query.includes(",")) {
      const valuesArray = query.trim().split(",");
      matches = valuesArray
    }
    else {
      return null
    }
    function coordinateFeature(lng: number, lat: number) {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        place_name: `Lat: ${lat} Lng: ${lng}`,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature',
      };
    }
    const coord1 = Number(matches[0]);
    const coord2 = Number(matches[1]);

    const isValidCoord1 = !isNaN(coord1);
    const isValidCoord2 = !isNaN(coord2);

    const geocodes = [];

    if ((coord1 < -90 || coord1 > 90) && isValidCoord1) {
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if ((coord2 < -90 || coord2 > 90) && isValidCoord2) {
      geocodes.push(coordinateFeature(coord2, coord1));
    }


    if (geocodes.length === 0 && isValidCoord1 && isValidCoord2) {
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes as Result[];
  };


  reverseGeoCode = (latd: number, lngd: number) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReverseCodeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngd},${latd}.json?access_token=${configJSON.access_token}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isAddAssetButtonReady = () => {
    return this.state.placeName && this.state.lat && this.state.lng
  }
  // Customizable Area End
}
