// App.js - WEB
import React, { Component, Suspense, lazy } from "react";
import firebase from 'firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import PageFallback from "../../components/src/PageFallback.web"

const AlertBlock = lazy(() => import('../../blocks/alert/src/AlertBlock.web'))
const ForgotPassword = lazy(() => import("../../blocks/forgot-password/src/ForgotPassword.web"))
const ForgotPasswordOTP = lazy(() => import("../../blocks/forgot-password/src/ForgotPasswordOTP"))
const NewPassword = lazy(() => import("../../blocks/forgot-password/src/ResetPassword.web"))
const TermsConditions = lazy(() => import("../../blocks/termsconditions/src/TermsConditions"))
const TermsConditionsDetail = lazy(() => import("../../blocks/termsconditions/src/TermsConditionsDetail"))
const TermsConditionsUsers = lazy(() => import("../../blocks/termsconditions/src/TermsConditionsUsers"))
const UserProfileBasicBlock = lazy(() => import("../../blocks/user-profile-basic/src/UserProfileBasicBlock.web"))
const Contactus = lazy(() => import("../../blocks/contactus/src/Contactus.web").then(module => ({default: module.StyledContactUs})));
const AddContactus = lazy(() => import("../../blocks/contactus/src/AddContactus"))
const Cfapiendpointforfetchingriskdata = lazy(() => import("../../blocks/cfapiendpointforfetchingriskdata/src/Cfapiendpointforfetchingriskdata"))
const Cfreportuploadingandcreation2 = lazy(() => import("../../blocks/cfreportuploadingandcreation2/src/Cfreportuploadingandcreation2"))
const MobileAccountLoginBlock = lazy(() => import("../../blocks/mobile-account-login/src/MobileAccountLoginBlock"))
const EmailAccountRegistration = lazy(() => import("../../blocks/email-account-registration/src/EmailAccountRegistration").then(module => ({default: module.StyledEmailAccountRegisttration})));
const Categoriessubcategories = lazy(() => import("../../blocks/categoriessubcategories/src/Categoriessubcategories"))
const Adminconsole2 = lazy(() => import("../../blocks/adminconsole2/src/Adminconsole2"))
const Cfdatabaseschemaforstoringriskdata = lazy(() => import("../../blocks/cfdatabaseschemaforstoringriskdata/src/Cfdatabaseschemaforstoringriskdata"))
const EmailAccountLoginBlock = lazy(() => import("../../blocks/email-account-login/src/EmailAccountLogin.web"))
import { LandingPageComp as LandingPage } from "../../blocks/landingpage/src/LandingPage.web";
const EducationalUserProfile = lazy(() => import("../../blocks/educational-user-profile/src/EducationalUserProfile"))
const Rolesandpermissions2 = lazy(() => import("../../blocks/rolesandpermissions2/src/Rolesandpermissions2"))
const Maps = lazy(() => import("../../blocks/maps/src/Maps"))
const OTPInputAuth = lazy(() => import("../../blocks/otp-input-confirmation/src/OTPInputAuth"))

const LoginOTPVerification = lazy(() => import('../../blocks/email-account-login/src/LoginOTPVerification.web'))
const AccountSuccessMessage = lazy(() => import("../../blocks/email-account-login/src/AccountSuccessMessage.web"))
const PasswordChangeResult = lazy(() => import('../../blocks/forgot-password/src/PasswordChangeResult.web'))
const ForgotPasswordOTPVerification = lazy(() => import('../../blocks/forgot-password/src/ForgotPasswordOTPVerification.web'))
const UserProfileEdit = lazy(() => import('../../blocks/user-profile-basic/src/UserProfileEdit.web'))
const AssetReport = lazy(() => import("../../blocks/cfreportuploadingandcreation2/src/AssetReport.web"))
const PortfolioReport = lazy(() => import("../../blocks/cfreportuploadingandcreation2/src/PorfolioReport.web"))

const MyAssets = lazy(() => import("../../blocks/dashboard/src/MyAssets.web"))
const CreateAssets = lazy(() => import("../../blocks/customform/src/CreateAssets.web"))

const MyPortfolios = lazy(() => import("../../blocks/dashboard/src/MyPortfolios.web"))
const MyPortfolioDetails = lazy(() => import("../../blocks/dashboard/src/MyPortfolioDetails.web"))


const routeMap = {
  PortfolioReport: {
    component: PortfolioReport,
    path: "/PortfolioReport/:id",
    exact: true
  },
  AssetReport: {
    component: AssetReport,
    path: "/AssetReport/:id",
    exact: true,
  },
  CreateAssets: {
    component: CreateAssets,
    path: "/ManageAsset",
    exact: true,
  },
  EditAssets: {
    component: CreateAssets,
    path: "/ManageAsset/:assetId",
    exact: true,
  },
  MyAssets: {
    component: MyAssets,
    path: '/MyAssets',
    exact: true
  },
  MyPortfolios: {
    component: MyPortfolios,
    path: '/MyPortfolios',
    exact: true
  },
  MyPortfoliosDetails: {
    component: MyPortfolioDetails,
    path: '/MyPortfolios/:id',
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/AccountDetails"
  },
  UserProfileBasicBlockTnC: {
    component: UserProfileBasicBlock,
    path: "/TermsAndConditions"
  },
  UserProfileBasicBlockAboutUs: {
    component: UserProfileBasicBlock,
    path: "/AboutUs"
  },
  UserProfileBasicBlockTrashAsset: {
    component: UserProfileBasicBlock,
    path: "/TrashAsset"
  },
  UserProfileBasicBlockTrashPortfolio: {
    component: UserProfileBasicBlock,
    path: "/TrashPortfolio"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Cfapiendpointforfetchingriskdata: {
    component: Cfapiendpointforfetchingriskdata,
    path: "/Cfapiendpointforfetchingriskdata"
  },
  Cfreportuploadingandcreation2: {
    component: Cfreportuploadingandcreation2,
    path: "/Cfreportuploadingandcreation2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Cfdatabaseschemaforstoringriskdata: {
    component: Cfdatabaseschemaforstoringriskdata,
    path: "/Cfdatabaseschemaforstoringriskdata"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/",
    exact: true
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  LoginOTPVerification: {
    component: LoginOTPVerification,
    path: "/LoginOTPVerification"
  },

  AccountSuccessMessage: {
    component: AccountSuccessMessage,
    path: "/AccountSuccessMessage"
  },

  PasswordChangeResult: {
    component: PasswordChangeResult,
    path: "/PasswordChangeResult"
  },
  ForgotPasswordOTPVerification: {
    component: ForgotPasswordOTPVerification,
    path: "/ForgotPasswordOTPVerification"
  },
  UserProfileEdit: {
    component: UserProfileEdit,
    path: "/UserProfileEdit"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div className="app-main">
        <Suspense fallback={<PageFallback />}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </Suspense>
      </div>
    );
  }
}

export default App;
