import React from "react";
// Customizable Area Start
import {
  Container,
} from "@material-ui/core";
import { createTheme, ThemeProvider , styled } from "@material-ui/core/styles";
import "../src/Search.css"

export const SearchBar = styled('div')(({ theme }) => ({
  position: 'relative',
  display: "flex",
  alignItems: "center",
  borderRadius: "15px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  padding: "6px 4px",
  '&:hover': {
    backgroundColor: "#FFFFFF",
  },
  marginRight: theme.spacing(2),
  marginLeft: "80px",
  width: "fit-content",
  height: 44,
  boxSizing: "border-box",
  [theme.breakpoints.down('md')]: {
    marginLeft: "29px",
    marginRight: "32px",
  },
  [theme.breakpoints.down('sm')]: {
    height: 40,
    marginLeft: "15px",
    margin: "0 15px",
    boxSizing: "border-box",
  },
  [theme.breakpoints.down('xs')]: {
    margin: "0px",
  },
  "& .mapboxgl-ctrl-geocoder--input": {
    fontFamily: "Mukta Mahee, sans-serif",
    fontSize: 16
  },
  "& .mapboxgl-ctrl-geocoder--suggestion": {
    fontSize: 16,
    fontFamily: "Mukta Mahee, sans-serif",
  },
}));
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          {/* Customizable Area Start */}  
          <SearchBar data-test-id="searchBar">
            <div  id="geocoder"></div>
          </SearchBar>
          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
// Customizable Area End
