import React from "react";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import "mapbox-gl/dist/mapbox-gl.css";
import "../src/index.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { alertIcon } from "./assets";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import AppHeader from "../../../components/src/AppHeader.web";
import LandingPageModal from "../../../components/src/LandingPageModal.web.";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={this.props.classes.mainContainer}>
          <AppHeader
            isLoggedin={false}
            pageName=""
            isShowNavItem={true}
            map={this.map.current as mapboxgl.Map}
          />
          <div
            ref={this.mapContainer}
            className={this.props.classes.mapContainer}
          />
          {this.state.isAddingAsset && (
            <Box className={this.props.classes.addAssetContainer}>
              <Typography className={this.props.classes.assetTitle}>
                {this.state.placeName}
              </Typography>
              <Box className={this.props.classes.assetSmallContainer}>
                <Box className={this.props.classes.lngContainer}>
                  <Typography className={this.props.classes.assetSmallTitle}>
                    {configJSON.textLongitude}
                  </Typography>
                  <Typography className={this.props.classes.assetSmallTitle}>
                    {this.state.lng.toFixed(5)}
                  </Typography>
                </Box>
                <Box className={this.props.classes.verticalLine} />
                <Box className={this.props.classes.latContainer}>
                  <Typography className={this.props.classes.assetSmallTitle}>
                    {configJSON.textLatitude}
                  </Typography>
                  <Typography className={this.props.classes.assetSmallTitle}>
                    {this.state.lat.toFixed(5)}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                data-test-id="addAssetBtn"
                className={this.props.classes.assetColorButton}
                onClick={
                  this.isAddAssetButtonReady() ? this.onAddAsset : undefined
                }
              >
                {this.isAddAssetButtonReady() ? (
                  configJSON.textAddAsset
                ) : (
                  <CircularProgress size={16} color="inherit" />
                )}
              </Button>
            </Box>
          )}
          <Box
            className={this.props.classes.container}
            component={"div"}
            onClick={this.handleModal}
          >
            {!this.state.isAddingAsset && (
              <Typography className={this.props.classes.smallTitle}>
                {configJSON.mapSelectionTitle}
              </Typography>
            )}
            <Box className={this.props.classes.hazardBoxWrapper}>
              <Box
                className={this.props.classes.labelAndSelectContainer}
                component={"div"}
              >
                <InputLabel id="yearLabel" className={this.props.classes.label}>
                  {configJSON.yearText}
                </InputLabel>
                <Select
                  className={this.props.classes.selectDropdown}
                  labelId="yearLabel"
                  label="Year"
                  classes={{
                    select: this.props.classes.select,
                    icon: this.props.classes.icon
                  }}
                  id="selectDropdown"
                  data-test-id="selectDropdown"
                  name="selectedYear"
                  input={<InputBase />}
                  IconComponent={ExpandMoreIcon}
                  value={this.state.selectedYear}
                  onChange={this.handleChange}
                  disabled={!this.state.isLoggedIn}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    marginThreshold: 0,
                    getContentAnchorEl: null,
                    classes: { paper: this.props.classes.customMenuPaper }
                  }}
                >
                  {this.state.available_years.map(year => (
                    <MenuItem
                      classes={{
                        root: this.props.classes.menuItem,
                        gutters: this.props.classes.menuItemGutters
                      }}
                      key={year}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className={this.props.classes.line} />
              <Box className={this.props.classes.labelAndSelectContainerTwo}>
                <InputLabel
                  id="hazardLabel"
                  className={this.props.classes.label}
                >
                  {configJSON.hazardText}
                </InputLabel>
                <Select
                  className={this.props.classes.selectDropdown}
                  labelId="hazardLabel"
                  label="Hazard"
                  classes={{
                    select: this.props.classes.select,
                    icon: this.props.classes.iconTwo,
                    selectMenu: this.props.classes.drawer
                  }}
                  id="selectDropdownHazard"
                  data-test-id="selectDropdownHazard"
                  name="selectedHazard"
                  input={<InputBase />}
                  IconComponent={ExpandMoreIcon}
                  value={this.state.selectedHazard}
                  onChange={this.handleChange}
                  defaultValue={this.state.selectedHazard}
                  disabled={!this.state.isLoggedIn}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    marginThreshold: 0,
                    getContentAnchorEl: null,
                    classes: { paper: this.props.classes.customMenuPaper }
                  }}
                >
                  {this.state.available_hazards.map(hazard => (
                    <MenuItem
                      classes={{
                        root: this.props.classes.menuItem,
                        gutters: this.props.classes.menuItemGutters
                      }}
                      key={hazard}
                      value={hazard}
                    >
                      {hazard}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
          <Modal
            open={this.state.isShowErrorModal}
            data-test-id="isFailedModal"
          >
            <Box className={this.props.classes.modalContainer}>
              <img
                src={alertIcon}
                className={this.props.classes.closeIcon}
                alt=""
              />
              <Typography className={this.props.classes.errorMessage}>
                {this.state.error}
              </Typography>
            </Box>
          </Modal>
        </Box>
        <LandingPageModal
          isModalOpen={this.state.isModalOpen}
          handleModal={this.handleModal}
          data-test-id="loginModal"
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {} = (theme: {
  breakpoints: { down: (arg0: string) => string };
}) => ({
  customMenuPaper: {
    maxHeight: "74px !important",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: "0px",
      color: "#2A4E51"
    }
  },
  mainContainer: {
    backgroundColor: "#2A4E51"
  },
  mapContainer: {
    position: "absolute",
    top: "74px",
    bottom: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      top: "70px"
    }
  },
  addAssetContainer: {
    position: "absolute",
    bottom: "225px",
    left: "2%",
    width: "254px",
    padding: "15px 20px",
    backgroundColor: "#2A4E51",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      width: "243px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "242px",
      bottom: "215px"
    },
    "@media (max-width: 640px)": {
      width: "50%",
      bottom: "195px",
      boxSizing: "border-box"
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "190px"
    },
    "@media (max-width: 320px)": {
      width: "80%"
    }
  },
  assetTitle: {
    fontFamily: "Mukta Mahee",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: 700,
    color: "#FFF",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      WebkitLineClamp: 3
    }
  },
  assetColorButton: {
    color: "#333",
    backgroundColor: "#E1AD01",
    fontFamily: "Mukta Mahee, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "normal",
    textTransform: "capitalize",
    borderRadius: "8px",
    height: 46,
    width: "100%",
    padding: "10px 15px",
    boxSizing: "border-box",
    "@media (max-width: 640px)": {
      height: 48
    },
    "&:hover": {
      backgroundColor: "#E1AD01"
    }
  },
  assetSmallTitle: {
    color: "#FFFFFF",
    fontFamily: "Mukta Mahee",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    textTransform: "capitalize",
    height: 24,
    maxWidth: 100,
    marginRight: 2,
    overflow: "hidden"
  },
  assetSmallContainer: {
    height: 50,
    width: "100%",
    padding: "10px 0",
    display: "flex"
  },
  lngContainer: {
    width: "100px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  latContainer: {
    width: "100px",
    height: "100%",
    marginLeft: 14,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  verticalLine: {
    backgroundColor: "#FFF",
    width: "1px",
    height: "100%"
  },
  container: {
    position: "absolute",
    bottom: "40px",
    left: "2%",
    width: "294px",
    maxHeight: "190px",
    backgroundColor: "#2A4E51",
    borderRadius: "20px",
    [theme.breakpoints.down("md")]: {
      width: "284px"
    },
    "@media (max-width: 640px)": {
      width: "50%"
    },
    "@media (max-width: 320px)": {
      width: "80%"
    }
  },
  smallTitle: {
    paddingTop: "12px",
    paddingLeft: "20px",
    fontFamily: "Mukta Mahee",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
      paddingLeft: "15px",
      paddingTop: "9px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px"
    }
  },
  select: {
    backgroundColor: "transparent",
    fontSize: "22px",
    color: "#FFF",
    fontWeight: 400,
    fontFamily: "Mukta Mahee",
    fontStyle: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px"
    }
  },
  selectHazard: {
    backgroundColor: "transparent",
    fontSize: "20px",
    color: "#FFF",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  icon: {
    fontSize: "25px",
    color: "#FFF",
    top: "-3px"
  },
  iconTwo: {
    fontSize: "25px",
    color: "#FFF",
    top: "-4px"
  },
  label: {
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Mukta Mahee",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  hazardBoxWrapper: {
    padding: 20,
    paddingBottom: 6,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
      paddingBottom: 6
    }
  },
  line: {
    border: "1px solid #FFF",
    width: "100%",
    borderRadius: 5,
    margin: "4px auto 12px"
  },
  drawer: { backgroundColor: "transparent" },
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#2A4E51",
    height: "184px",
    width: "423px",
    boxShadow: `5px 20px 40px 0px rgba(0, 0, 0, 0.20)`,
    padding: "10px 2px",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "50vw"
    },
    [theme.breakpoints.down("xs")]: {
      width: "92vw"
    }
  },
  errorMessage: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Mukta Mahee",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    minHeight: "28px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 10px"
    }
  },
  closeIcon: {
    fontSize: "50px",
    color: "#E1AD01"
  },
  selectDropdown: {
    width: "100%",
    height: 40,
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      boxSizing: "border-box"
    }
  },
  menuItem: {
    fontFamily: "Mukta Mahee",
    borderBottom: ".5px solid #EBEBEB"
  }
});
export const LandingPageComp = withStyles(webStyle)(LandingPage);
// Customizable Area End
