import React, { Component } from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  InputBase,
  Button,
  ButtonProps,
  Drawer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import { configJSON } from "../../blocks/landingpage/src/LandingPageController.web";
import { Link } from "react-router-dom"
import { getStorageData } from "../../framework/src/Utilities";
import { imgLogo } from "../../blocks/email-account-login/src/assets";
import LandingPageModal from "./LandingPageModal.web.";
import Search from "../../blocks/search/src/Search.web"
import { Map } from "mapbox-gl";



const NavBar = styled('header')(({ theme }) => ({
  display: "flex",
  backgroundColor: "#28494B",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "15px",
  [theme.breakpoints.down('xs')]: {
    padding: "15px 12px",
  },
}));


const ImgContainer = styled('img')(({ theme }) => ({
  height: "22px",
  [theme.breakpoints.down('xs')]: {
    height: "20px",
    position: "relative",
  },
}));

const NavItemContainer = styled('div')(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down('sm')]: {
    display: "none",
  },
  alignItems: "center",
  width: "50%",
  justifyContent: "space-between",
  [theme.breakpoints.down('md')]: {
    width: "71%",
  },
}));

const NavLogoContainer = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down('sm')]: {
    width: "-webkit-fill-available",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down('xs')]: {
    alignItems: "center",
  },
}));

const MenuButton = styled('div')(({ theme }) => ({
  padding: "0px",
  display: "none",
  [theme.breakpoints.down('sm')]: {
    display: "block",
    position: "relative",
    top: "0",
    marginLeft: "24px",
  },
  [theme.breakpoints.down('xs')]: {
    display: "block",
    position: "relative",
    marginLeft: "10px",
  },
  alignItems: "center",
  justifyContent: "center",
}));
const MenuButtonTwo = styled('div')(({ theme }) => ({
  padding: "0px",
  display: "none",
  [theme.breakpoints.down('sm')]: {
    display: "block",
    position: "relative",
    marginRight: "4px",
  },
  alignItems: "center",
  justifyContent: "center",
}));





const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  backgroundColor: "#FFFFFF",
  fontFamily: "Mukta Mahee, sans-serif",
  fontSize: '13px',
  fontWeight: 700,
  padding: "10px 31px 10px 35px",
  textTransform: "capitalize",
  borderRadius: "8px",
  height: "max-content",
  '&:hover': {
    backgroundColor: "#FFFFFF",
  },
}));

const ColorIconButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  backgroundColor: "transparent",
  fontFamily: "Mukta Mahee, sans-serif",
  fontSize: '13px',
  fontWeight: 700,
  textTransform: "capitalize",
  borderRadius: "8px",
  boxShadow: "none",
  height: "max-content",
  '&:hover': {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}))

const DrawerColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  width: "150px",
  backgroundColor: "#FFFFFF",
  fontFamily: "Mukta Mahee, sans-serif",
  fontSize: '13px',
  fontWeight: 700,
  padding: "10px 31px 10px 35px",
  textTransform: "capitalize",
  borderRadius: "8px",
  height: "max-content",
  '&:hover': {
    backgroundColor: "#FFFFFF",
  },
}));

const SearchBar = styled('div')(({ theme }) => ({
  position: 'relative',
  display: "flex",
  alignItems: "center",
  borderRadius: "15px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  padding: "3px 4px",
  '&:hover': {
    backgroundColor: "#FFFFFF",
  },
  marginRight: theme.spacing(2),
  marginLeft: "80px",
  width: '280px',
  
  [theme.breakpoints.down('md')]:{
    width: "260px",
    marginLeft: "29px",
    marginRight: "32px",
  },
  [theme.breakpoints.down('sm')]: {
    width: '310px',
    marginLeft: "15px",
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',

    marginRight:"auto",
  },

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: "8px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '32ch',
    [theme.breakpoints.up('md')]: {
      width: '32ch',
    },
  },
}));

const NotLogInSearchOverlay = styled('div')({
  position: 'absolute',
  inset: 0,
  zIndex: 1,
  cursor: 'pointer'
})

// Customizable Area End



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});



type MyProps = { isLoggedin?: boolean, pageName: string, handleModal?: () => void, isShowNavItem?: boolean ,map? : Map};

type MyState = { isModalOpen: boolean; _isLoggedIn: boolean, onChangeValue?: (value: boolean) => void, isDrawerOpen: boolean };

export default class AppHeader extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    // Customizable Area Start
    this.state = {
      isModalOpen: false,
      _isLoggedIn: false,
      onChangeValue: undefined,
      isDrawerOpen: false
    }
    // Customizable Area End
  }

  // Customizable Area Start

  checkIfUserLoggedIn = async () => {
    const authToken = await getStorageData('authToken');
    this.setState({ _isLoggedIn: Boolean(authToken) });
  }

  handleDrawer = () => {
    this.setState(prev => ({
      isDrawerOpen: !prev.isDrawerOpen
    }))
  };

  async componentDidMount(): Promise<void> {
    await this.checkIfUserLoggedIn();
  }

  handleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }
  // Customizable Area End

  render() {

    const { isLoggedin, pageName, isShowNavItem } = this.props
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavBar>
          <NavLogoContainer>
            <Link style={webStyle.logo}
              to={"/"}
            >
              <ImgContainer src={imgLogo} alt="Ventrx" />
            </Link>
          {!isLoggedin && pageName === "" ? this.state._isLoggedIn ?<Search navigation={undefined} id={""} map={this.props.map} /> :
              <SearchBar onClick={this.handleModal}>
                <NotLogInSearchOverlay />
                <SearchIcon style={webStyle.searchIcon} />
                <StyledInputBase
                  disabled={!this.state._isLoggedIn}
                  placeholder={"Search"}
                  inputProps={{ 'aria-label': 'Search' }}
                />
              </SearchBar>
              : null
            }
          </NavLogoContainer>

          {isShowNavItem ?
            <NavItemContainer>
              <Box style={webStyle.navItems}>
                {this.state._isLoggedIn ?
                  <Link style={webStyle.link} to="/MyAssets">
                    My Assets
                  </Link> :
                  <Typography style={webStyle.noLink} onClick={this.handleModal}>
                    My Assets
                  </Typography>
                }
                <Box component={"div"} style={(window.location.pathname === "/MyAssets" || window.location.pathname.includes("/ManageAsset")) ? webStyle.activeLine : webStyle.line} />
              </Box>
              <Box style={webStyle.navItems}>
                {this.state._isLoggedIn ?
                  <Link style={webStyle.link} to="/MyPortfolios">
                    My Portfolios
                  </Link> :
                  <Typography style={webStyle.noLink} onClick={this.handleModal}>
                    My Portfolios
                  </Typography>
                }
                <Box component={"div"} style={window.location.pathname.includes("/MyPortfolios") ? {...webStyle.activeLine, width: 'calc(100% - 6px)'} : webStyle.line} />
              </Box>
              <Box style={webStyle.navItems}>
                {this.state._isLoggedIn ?
                  <a style={webStyle.link} href="https://ventrx.com/" target="_blank">
                    Methods
                  </a>:
                  <Typography style={webStyle.noLink} onClick={this.handleModal}>
                    Methods
                  </Typography>
                }
                <Box component={"div"} style={window.location.pathname === "" ? webStyle.activeLine : webStyle.line} />
              </Box>
              <Box style={webStyle.navItems}>
                <Link style={webStyle.link} to="/Contactus">
                  Contact Us
                </Link>
                <Box component={"div"} style={window.location.pathname === "/Contactus" ? webStyle.activeLine : webStyle.line} />
              </Box>
              {this.state._isLoggedIn ?
                <Link to='/AccountDetails'>
                  <ColorIconButton variant="contained" style={{ padding: 5, minWidth: "auto", marginRight: 8 }}>
                    <Typography style={webStyle.loginText}>
                      <SettingsIcon htmlColor="#fff" style={webStyle.settingIcon} />
                    </Typography>
                  </ColorIconButton>
                </Link> :
                <ColorButton variant="contained"
                  data-test-id="loginBtn"
                  onClick={this.handleModal}>
                  <Typography style={webStyle.loginText}>
                    {configJSON.loginText}
                  </Typography>
                </ColorButton>
              }
            </NavItemContainer> : null
          }

          {isShowNavItem ? !isLoggedin && pageName === "" ?
            <MenuButton onClick={this.handleDrawer} >
              <MenuIcon fontSize="large" style={webStyle.menuIcon} />
            </MenuButton> :
            <MenuButtonTwo onClick={this.handleDrawer} >
              <MenuIcon fontSize="large" style={webStyle.menuIcon} />
            </MenuButtonTwo> : null
          }
        </NavBar>

        <Drawer anchor="right" open={this.state.isDrawerOpen} onClose={this.handleDrawer}>
          <Box style={webStyle.drawerContainer}>
            <Box style={webStyle.drawerSubContainer}>
              <Link style={webStyle.drawerLogo} to={"/"}>
                <ImgContainer src={imgLogo} alt="Ventrx" />
              </Link>
              <Box style={webStyle.drawerNavItems}>
                {this.state._isLoggedIn ?
                  <Link style={webStyle.drawerLink} to="/MyAssets">
                    My Assets
                  </Link> :
                  <Typography style={webStyle.drawerNoLink} onClick={this.handleModal}>
                    My Assets
                  </Typography>
                }
                <Box component={"div"} style={window.location.pathname === "/MyAssets" || window.location.pathname.includes("/ManageAsset") ? webStyle.activeLine : webStyle.line} />
              </Box>
              <Box style={webStyle.drawerNavItems}>
                {this.state._isLoggedIn ?
                  <Link style={webStyle.drawerLink} to="/MyPortfolios">
                    My Portfolios
                  </Link> :
                  <Typography style={webStyle.drawerNoLink} onClick={this.handleModal}>
                    My Portfolios
                  </Typography>
                }
                <Box component={"div"} style={window.location.pathname.includes("/MyPortfolios") ? {...webStyle.activeLine, width: '100%'} : webStyle.line} />
              </Box>
              <Box style={webStyle.drawerNavItems}>
                {this.state._isLoggedIn ?
                  <a style={webStyle.drawerLink} href="https://ventrx.com/" target="_blank">
                    Methods
                  </a> :
                  <Typography style={webStyle.drawerNoLink} onClick={this.handleModal}>
                    Methods
                  </Typography>
                }
                <Box component={"div"} style={window.location.pathname === "" ? webStyle.activeLine : webStyle.line} />
              </Box>
              <Box style={webStyle.drawerNavItems}>
                <Link style={webStyle.drawerLink} to="/Contactus">
                  Contact Us
                </Link>
                <Box component={"div"} style={window.location.pathname === "/Contactus" ? webStyle.activeLine : webStyle.line} />
              </Box>
              {this.state._isLoggedIn ?
                <Link to='/AccountDetails'>
                  <ColorIconButton variant="contained">
                    <Typography style={webStyle.loginText}>
                      <SettingsIcon htmlColor="#fff" style={webStyle.settingIcon} />
                    </Typography>
                  </ColorIconButton>
                </Link> :
                <DrawerColorButton variant="contained"
                  data-test-id="loginBtn"
                  onClick={this.handleModal}
                  style={webStyle.drawerColorBtn}>
                  <Typography style={webStyle.loginText} component={"div"} onClick={this.handleDrawer}>
                    {configJSON.loginText}
                  </Typography>
                </DrawerColorButton>
              }
            </Box>
          </Box>
        </Drawer>
        <LandingPageModal isModalOpen={this.state.isModalOpen} handleModal={this.handleModal} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  link: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    margin: "0 3px",
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee",
    textDecoration: "none",
  },
  noLink: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    margin: "0 3px",
    textTransform: "capitalize" as 'capitalize',
    fontFamily: "Mukta Mahee",
    textDecoration: "none",
    cursor: "pointer",
  },
  drawerLink: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee",
    textDecoration: "none",
  },
  drawerColorBtn: { marginTop: "4px" },
  drawerNoLink: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    textTransform: "capitalize" as 'capitalize',
    fontFamily: "Mukta Mahee",
    textDecoration: "none",
    cursor: "pointer",
  },
  btnLink: {
    fontSize: "16px",
    color: "#333333",
    fontWeight: 700,
    margin: "0 3px",
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee, sans-serif",
    textDecoration: "none",
  },
  activeLine: {
    width: "80px",
    backgroundColor: "#E1AD01",
    height: "2.5px"
  },
  line: {
    width: "80px",
    backgroundColor: "transparent",
    height: "2px"
  },
  navItems: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    margin: "0 3px",
    textTransform: "capitalize" as 'capitalize',
    fontFamily: "Mukta Mahee, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column"
  },
  drawerNavItems: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    margin: "15px 0",
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column" as "column"
  },
  navItemsText: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee, sans-serif",
  },
  navItemsTextMob: {
    fontSize: "18px",
    color: "#FFFFFF",
    fontWeight: 700,
    margin: "12px 0",
    textTransform: "capitalize" as 'capitalize',
    cursor: "pointer",
    fontFamily: "Mukta Mahee, sans-serif",
  },
  logo: {
    fontSize: "34px",
    fontFamily: "Mukta Mahee, sans-serif",
    fontWeight: "bold" as "bold",
    color: "#FFFFFF",
    textAlign: "center" as "center",
    cursor: "pointer",
    textDecoration: "none"
  },
  drawerLogo: {
    fontSize: "34px",
    fontFamily: "Mukta Mahee, sans-serif",
    fontWeight: "bold" as "bold",
    color: "#FFFFFF",
    textAlign: "left" as "left",
    cursor: "pointer",
    textDecoration: "none",
    marginBottom: "20px"
  },
  buttonStyle: {
    marginTop: "30px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  loginText: {
    color: "#000000",
    fontFamily: "Mukta Mahee, sans-serif",
    fontSize: '16px',
    fontWeight: 700,
  },
  mapContainer: { height: "92vh" },
  navLoginBtn: {
    height: "max-content",
    margin: "0 10px",
    fontSize: "13px",
    color: "#000",
    fontWeight: "bold" as "bold",
    textTransform: "capitalize" as "capitalize",
    padding: "10px 31px 10px 35px",
    borderRadius: "8px"
  },
  searchIcon: {
    marginRight: "5px",
    marginLeft: "5px",
    color: "#94A3B8"
  },
  drawerContainer: {
    backgroundColor: "#28494B",
    display: "flex",
    flexDirection: 'column' as "column",
    justifyContent: "flex-start",
    height: "100%",
  },
  drawerSubContainer: {
    backgroundColor: "#28494B",
    display: "flex",
    flexDirection: 'column' as "column",
    justifyContent: "space-evenly",
    paddingTop: "25px",
    paddingRight: "50px",
    paddingLeft: "11px",
  },
  modalContainer: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "470px",
    height: "338px",
    bgcolor: "#2A4E51",
    boxShadow: `5px 20px 40px 0px rgba(0, 0, 0, 0.20)`,
    padding: "9px 18px 43px",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  modalCancelBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "12px"
  },
  modalTextFirstRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingY: "5px",
    color: "#FFFFFF",
    fontFamily: "Mukta Mahee, sans-serif",
  },
  modalOrText: {
    margin: "0 4px",
    fontFamily: "Mukta Mahee, sans-serif",
    fontStyle: "normal",
    fontSize: "26px",
    fontWeight: 400
  },

  modalTextCreateAccount: {
    margin: "2px",
    fontWeight: 600,
    fontSize: "26px",
    fontFamily: "Mukta Mahee, sans-serif",
    fontStyle: "normal",
    color: "#FFF"
  },
  modalRowTwoText: {
    width: "100%",
    textAlign: "center" as "center",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "26px",
    marginTop: "-12px",
    marginBottom: "5px",
    textTransform: "lowercase" as "lowercase",
    fontFamily: "Mukta Mahee, sans-serif",
  }
  ,
  modalBtnsContainer: {
    margin: "41px 30px 25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalCloseIcon: { fontSize: "31px", color: "#FFFFFF" },
  modalCancleBtn: {
    backgroundColor: "#2A4E51",
    boxShadow: "none",
    border: "none",
    color: "#000000",
    marginTop: "-3px",
    marginRight: "-12px",
    width: "10px 32px 10px 31px",
    '&:hover': {
      backgroundColor: "#2A4E51",
    },
    '@media (max-width: 600px)': {
      display: "none"
    },
  },

  modalLogInBtn: {
    width: "176px",
    margin: "0 10px",
    fontSize: "16px",
    color: "#333",
    textTransform: "capitalize" as "capitalize",
    padding: "16px 0",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Mukta Mahee",
    fontWeight: 600,
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
  },
  modalBtn: {
    width: "176px",
    margin: "0 10px",
    fontSize: "16px",
    color: "#333",
    textTransform: "capitalize" as "capitalize",
    padding: "16px 0",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Mukta Mahee",
    fontWeight: 600,
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
  },
  menuIcon: {
    color: "#E1AD01",
  },
  settingIcon: { fontSize: '34px' }
};
// Customizable Area End
