const configJSON = require('../../blocks/landingpage/src/config');

export const fetchTile = (tile_url: any, tileX: any, tileY: any, zoom: any) => {
    const full_tile_url = `${tile_url}/${zoom}/${tileX}/${tileY}.png?access_token=${configJSON.access_token}`;
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.crossOrigin = "Anonymous";
        img.src = full_tile_url;
    });
}

export const getPixelColor = (img: any, offsetX: any, offsetY: any) => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas?.getContext("2d");
    ctx?.drawImage(img, 0, 0);
    const imageData = ctx?.getImageData(offsetX, offsetY, 1, 1).data;
    if (imageData) {
        return {
            r: imageData[0],
            g: imageData[1],
            b: imageData[2],
            a: imageData[3] / 255,
        };
    }
}

export const latLonToPixels = (lat: number, lon: number, zoom: number) => {
    const tileCount = Math.pow(2, zoom);
    let x = (lon + 180) / 360;
    let y =
        (1 -
            Math.log(
                Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
            ) /
            Math.PI) /
        2;
    console.log("@@==latLonToPixels", {
        x: x * tileCount * 256,
        y: y * tileCount * 256,
    });
    return {
        x: x * tileCount * 256,
        y: y * tileCount * 256,
    };
}

export const getTileAndOffset = (lat: number, lon: number, zoom: number) => {
    const pixelCoords = latLonToPixels(lat, lon, zoom);
    return {
        tileX: Math.floor(pixelCoords.x / 256),
        tileY: Math.floor(pixelCoords.y / 256),
        offsetX: Math.floor(pixelCoords.x % 256),
        offsetY: Math.floor(pixelCoords.y % 256),
    };
}

export const mapRGBAToRating = (color: any) => {
    // Define the rating ranges and their corresponding ratings
    const ratings_list = ["A", "B", "C", "D", "E"];

    const ratingRanges = [
        { min: [0, 180, 0, 0.5], max: [80, 260, 80, 1], rating: ratings_list[0] }, // green
        { min: [225, 225, 0, 0.5], max: [260, 260, 250, 1], rating: ratings_list[1] }, // yellow
        { min: [225, 60, 0, 0.5], max: [260, 150, 255, 1], rating: ratings_list[2] }, // orange
        { min: [225, 0, 0, 0.5], max: [260, 50, 50, 1], rating: ratings_list[3] }, // red
        { min: [80, 0, 0, 0.5], max: [190, 30, 30, 1], rating: ratings_list[4] }, // dark red
    ];

    const rgba = [color?.r, color?.g, color?.b, color?.a];

    let rating = null;
    let check = 0;

    for (let r = 0; r < 5; r++) {
        check = 1;
        for (let i = 0; i < 4; i++) {
            if (
                rgba[i] < ratingRanges[r].min[i] ||
                rgba[i] > ratingRanges[r].max[i]
            ) {
                check = 0;
                break;
            }
        }
        if (check == 1) {
            rating = ratingRanges[r].rating;
            break;
        }
    }

    // If a matching range is found, return its associated rating; otherwise, return 'Unknown'
    if (check == 1) {
        return rating;
    } else {
        return "Unknown";
    }
}