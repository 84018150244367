Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.searchApiContentType = 'application/json'
exports.getSearchApiEndPoint = 'search/users'
exports.errorTitle = 'Error'
exports.access_token = "pk.eyJ1IjoidmVudHJ4IiwiYSI6ImNsbnNoZWw1bjFjYWMybHBqcGluOXIxYnkifQ.Ry8D8ESFwb6CiuZ-20Q8ug"
// Customizable Area End
