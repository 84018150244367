Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.getAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.contentTypeFormData = "multipart/form-data";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.loginText = "Login";
exports.createAccountText = "Create Account";
exports.modalRowTwoText = "to access your physical climate risk";
exports.orText = "or";
exports.myAssetsText = "My Assets";
exports.myPortfolioText = "My Portfolio";
exports.contactUsText = "Contact Us";
exports.searchText = "Search...";
exports.methodsText = "methods";
exports.mainLogoText = "Ventrx";
exports.mapSelectionTitle = "Map Selection";
exports.yearText = "Year";
exports.hazardText = "Hazard";
exports.getAllRastersEndPoint = "bx_block_cfdatabaseschemaforstoringriskdata/risk_managements";
exports.getFilterRiskEndPoint = "bx_block_cfdatabaseschemaforstoringriskdata/risk_managements/filter_risk";
exports.addAssetEndPoint = "bx_block_content_management/assets";
exports.defaultRasterLink = `https://api.mapbox.com/v4/ventrx.13c6jovz`;
exports.access_token = "pk.eyJ1IjoidmVudHJ4IiwiYSI6ImNsbnNoZWw1bjFjYWMybHBqcGluOXIxYnkifQ.Ry8D8ESFwb6CiuZ-20Q8ug"
exports.btnExampleTitle = "CLICK ME";
exports.textLatitude= "Latitude";
exports.textLongitude= "Longitude";
exports.textAddAsset = "Add Asset";
exports.fetchRasterLink = "bx_block_cfdatabaseschemaforstoringriskdata/risk_ratings"
exports.fetchRasterLinkMethod = "GET"
// Customizable Area End